import './styles/Footer.css';
import linkedin from '../assets/contacts/linkedin.png';
import instagram from '../assets/contacts/instagram.png';
import whatsapp from '../assets/contacts/whatsapp.png';
import email from '../assets/contacts/email.png';

const Footer = () => {
  return (
    <footer className="mainFooter">
      <h2 className="textCard__title" id="contato">Entrar em contato</h2>
      <p className="textCard__paragraph">Estamos aqui para facilitar sua experiência e fornecer todas as informações
        necessárias para atender às suas necessidades.
        Não hesite em utilizar nossos contatos para fazer suas solicitações. Seja por e-mail ou telefone, estamos
        prontos para atendê-lo com eficiência e cortesia.
        Sua satisfação é nossa prioridade, e estamos comprometidos em oferecer um serviço excepcional em todas as
        interações.
        Aguardamos ansiosamente para ajudá-lo.</p>
      <section className="mainFooter__social">
        <a
          href="https://www.linkedin.com/company/wardie/"
          target="_blank"
          rel="noreferrer"
          className="mainFooter__link"
        >
          <div className='mainFooter__social--contato'>
            <img className='mainFooter__contato--img' src={linkedin} alt="linkedin"/>
            <p>@wardie</p>
          </div>
        </a>
        <a
          href="https://www.instagram.com/wardie.com.br/"
          target="_blank"
          rel="noreferrer"
          className="mainFooter__link"
        >
          <div className='mainFooter__social--contato'>
            <img className='mainFooter__contato--img' src={instagram} alt="instagram"/>
            
            <p>@wardie.com.br</p>
          </div>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5585989284862"
          target="_blank"
          rel="noreferrer"
          className="mainFooter__link"
        >
          <div className='mainFooter__social--contato'>
            <img className='mainFooter__contato--img' src={whatsapp} alt="whatsapp"/>
            
            <p>(85)98928-4862</p>
          </div>
        </a>
        <a
          href="mailto:howard@wardie.com.br"
          target="_blank"
          rel="noreferrer"
          className="mainFooter__link"
        >
          <div className='mainFooter__social--contato'>
            <img className='mainFooter__contato--img' src={email} alt="email"/>
            
            <p>
              howard@wardie.com.br
            </p>
          </div>
        </a>
      </section>
      <p className="mainFooter__text">Wardie Development © 2022</p>
    </footer>
  )
}

export default Footer;
